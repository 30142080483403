import {Component} from "@angular/core";
import {PopoverController} from "@ionic/angular";

@Component({
    selector: "app-cares-popover",
    templateUrl: "./cares-popover.component.html",
})
export class CaresPopoverComponent {
    public name: string;
    public description: string;
    constructor(private popoverCtrl: PopoverController) {}

    async closePopover() {
        await this.popoverCtrl.dismiss();
    }
}
