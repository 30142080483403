import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: "confirmation",
    templateUrl: "./confirmation.page.html",
    styleUrls: ["./confirmation.page.scss"]
})
export class ConfirmationPage implements OnInit {
    slotId: string;
    missionAssignationId: string;
    public patientListRoute: string;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.missionAssignationId = params.id;
            this.slotId = params.slotId;
            this.patientListRoute = `/tabs/identification-mission/${this.missionAssignationId}/slot/${this.slotId}`;
        });
    }

    ngOnInit() {}
}
