import {Component} from "@angular/core";
import {ModalController} from "@ionic/angular";

@Component({
    selector: "file-viewer-modal",
    templateUrl: "./file-viewer-modal.page.html",
    styleUrls: ["./file-viewer-modal.page.scss"]
})

export class FileViewerModalPage {
    public fileSrc;
    public fileName;
    public fileType;

    constructor(private modalCtrl: ModalController) {}

    public async closeModal() {
        const modal = await this.modalCtrl.getTop();
        return modal.dismiss();
    }
}
